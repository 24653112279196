
import {Component, Vue} from 'vue-property-decorator';

@Component<Voucher>({
    metaInfo()
    {
        return {
            title: this.title,
            titleTemplate: '%s | Půjčovna Haryků',
        };
    },
})
export default class Voucher extends Vue
{
    private title: string = 'Dárkový poukaz';
}
